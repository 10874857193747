.bookingform-wrapper {
  font-family: "Roboto", serif;
  background-color: #fff;
  -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
}
.bookingform-wrapper .close {
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
  width: 50px;
  line-height: 50px;
  height: 50px;
}
.bookingform-wrapper .close:before {
  line-height: 50px;
}
.bookingform-wrapper .close:hover {
  background-color: #e46e00;
  color: #fff;
}
.bookingform-wrapper .calendar_title {
  background-color: rgb(0, 179, 190);
  color: #fff;
  height: 50px;
  line-height: 50px;
}
.bookingform-wrapper .side {
  background-color: #f2f2f2;
  color: #333;
  padding-top: 5px;
  padding-bottom: 10px;
  font-size: 11px;
}
.bookingform-wrapper .side .fields .enfant {
  display: none;
}
.bookingform-wrapper .side .fields .field {
  border-bottom-color: #ddd;
}
.bookingform-wrapper .side .fields .field .tooltip_container {
  text-align: right;
  font-size: 20px;
  color: #d81a60;
}
.bookingform-wrapper .side .fields .field .tooltip_container span {
  cursor: pointer;
}
.bookingform-wrapper .side .fields .field .tooltip_container span:hover {
  color: #e46e00;
}
.bookingform-wrapper .side .fields .field label {
  text-transform: uppercase;
}
.bookingform-wrapper .side .fields .field select {
  color: #333;
  text-transform: capitalize;
  padding-right: 30px;
}
.bookingform-wrapper .side .fields .field select:focus {
  outline: none;
}
.bookingform-wrapper .side .fields .field.select_hotel {
  display: none;
}
.bookingform-wrapper .side .fields .field.select_hotel > div:after, .bookingform-wrapper .side .fields .field.select_treatment > div:after, .bookingform-wrapper .side .fields .field.adults > div:after, .bookingform-wrapper .side .fields .field.children > div:after {
  content: "";
  background-image: url("../css/images/dynamic_calendar/select_arrows.png");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-color: transparent;
  background-position: 0 center;
}
.bookingform-wrapper .side .fields .field.iata:hover {
  border-bottom-color: #e46e00;
}
.bookingform-wrapper .side .fields .field.iata #AccessCode {
  line-height: 25px;
  height: 25px;
  text-transform: none !important;
}
.bookingform-wrapper .side .fields .field.iata input {
  color: #333;
}
.bookingform-wrapper .side .fields .field.iata input:focus {
  outline: none;
}
.bookingform-wrapper .side .fields .field.bestprice {
  height: 50px;
}
@media (max-width: 770px) {
  .bookingform-wrapper .side .fields .field.bestprice {
    height: 80px;
  }
}
.bookingform-wrapper .side .fields .field.bestprice label {
  margin-bottom: 0;
}
@media (max-width: 770px) {
  .bookingform-wrapper .side .fields .field.bestprice label {
    margin-bottom: 5px;
  }
}
.bookingform-wrapper .side .fields .field.bestprice #bestprice {
  color: #d81a60;
}
.bookingform-wrapper .side .fields .field.bestprice #bestprice-loading {
  margin: 0;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;
  bottom: -10px;
  background-color: #d81a60;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.bookingform-wrapper .side .fields .field.bestprice #bestprice-loading:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  border-color: #f2f2f2;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  animation: bestprice-loading 1s ease forwards infinite;
}
.bookingform-wrapper .side .fields .field.booknowbuttoncontainer input {
  border-radius: 5px;
  font-family: "Roboto", serif;
  text-transform: uppercase;
  background: #d81a60;
  color: #fff;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
  height: 45px;
}
.bookingform-wrapper .side .fields .field.booknowbuttoncontainer input:hover {
  background: #e46e00;
  color: #fff;
}
.bookingform-wrapper .side .fields .field.booknowbuttoncontainer .quick-search-cancel {
  color: #333;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
  font-size: 10px;
  margin-top: 15px;
}
.bookingform-wrapper .side .fields .field.booknowbuttoncontainer .quick-search-cancel:hover {
  color: #e46e00;
}
.bookingform-wrapper #booking-calendar .ui-widget {
  font-family: "Roboto", serif;
}
.bookingform-wrapper #booking-calendar .ui-datepicker.ui-datepicker-inline .ui-datepicker-header .ui-datepicker-title {
  font-family: "Roboto", serif;
  color: #333;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: normal;
  font-style: normal;
  text-align: center;
}
.bookingform-wrapper #booking-calendar .ui-datepicker.ui-datepicker-inline .ui-datepicker-header .ui-corner-all:after {
  background-image: url("../css/images/dynamic_calendar/calendar_arrows.png");
  background-repeat: no-repeat;
  background-color: transparent;
  background-attachment: scroll;
}
.bookingform-wrapper #booking-calendar .ui-datepicker.ui-datepicker-inline .ui-datepicker-header .ui-corner-all.ui-state-hover {
  background: rgba(0, 0, 0, 0.1);
}
.bookingform-wrapper #booking-calendar .ui-datepicker.ui-datepicker-inline .ui-datepicker-header .ui-datepicker-prev:after {
  content: "";
  background-position: 0 0;
}
.bookingform-wrapper #booking-calendar .ui-datepicker.ui-datepicker-inline .ui-datepicker-header .ui-datepicker-next:after {
  content: "";
  background-position: -40px 0;
}
.bookingform-wrapper #booking-calendar .ui-datepicker-calendar thead th span {
  color: #333;
  font-size: 12px;
}
.bookingform-wrapper #booking-calendar .ui-datepicker-calendar tbody tr {
  border: 0;
}
.bookingform-wrapper #booking-calendar .ui-datepicker-calendar tbody td:hover .ui-state-default {
  background-color: rgb(0, 179, 190);
  color: #fff;
}
.bookingform-wrapper #booking-calendar .ui-datepicker-calendar tbody td:hover span.data {
  color: #fff;
}
.bookingform-wrapper #booking-calendar .ui-datepicker-calendar tbody td span.data {
  color: #666;
}
.bookingform-wrapper #booking-calendar .ui-datepicker-calendar tbody td.last-room-available:after {
  border-top-color: #da2e01;
  border-right-color: #da2e01;
}
.bookingform-wrapper #booking-calendar .ui-datepicker-calendar tbody td.best-price:after {
  border-top-color: yellowgreen;
  border-right-color: yellowgreen;
}
.bookingform-wrapper #booking-calendar .ui-datepicker-calendar tbody td.last-room-available.best-price:after {
  border-top-color: yellowgreen;
  border-right-color: #da2e01;
}
.bookingform-wrapper #booking-calendar .ui-datepicker-calendar tbody td.min-stay > .ui-state-default:after {
  background: rgb(0, 179, 190);
}
.bookingform-wrapper #booking-calendar .ui-datepicker-calendar tbody td.date-in-range .ui-state-default {
  background-color: rgb(0, 179, 190);
  color: #fff;
}
.bookingform-wrapper #booking-calendar .ui-datepicker-calendar tbody td.date-in-range span.data {
  color: #fff;
}
.bookingform-wrapper #booking-calendar .ui-datepicker-calendar tbody td.ui-state-disabled {
  opacity: 0.5;
}
.bookingform-wrapper #booking-calendar .ui-datepicker-calendar tbody td .ui-state-default {
  background: #f2f2f2;
  color: #666;
}
.bookingform-wrapper .legend_wrap {
  clear: both;
  position: relative;
  display: inline-block;
  width: 100%;
}
.bookingform-wrapper .legend_wrap .legend .best,
.bookingform-wrapper .legend_wrap .legend .last {
  color: #333;
  font-family: "Roboto", serif;
  font-size: 13px;
}
.bookingform-wrapper .legend_wrap .legend .best:before {
  border-top-color: yellowgreen;
  border-right-color: yellowgreen;
}
.bookingform-wrapper .legend_wrap .legend .last:before {
  border-top-color: #da2e01;
  border-right-color: #da2e01;
}
.bookingform-wrapper .legend_wrap .legend .minimum_stay:before {
  background: rgb(0, 179, 190);
}
.bookingform-wrapper #children-ages {
  border-top-color: #ddd;
}
@media (max-width: 770px) {
  .bookingform-wrapper #children-ages {
    border: 1px solid #ddd;
  }
}
.bookingform-wrapper #children-ages .label {
  text-transform: uppercase;
  color: #333;
  font-size: 18px;
}
.bookingform-wrapper #children-ages .child-list ul li label {
  text-transform: capitalize;
  color: #333;
  font-size: 13px;
}
.bookingform-wrapper #children-ages .child-list ul li select {
  color: #333;
  border-color: #ddd;
  padding-right: 30px;
  font-size: 12px;
}
.bookingform-wrapper #children-ages .child-list ul li select:focus, .bookingform-wrapper #children-ages .child-list ul li select:hover {
  border-color: #e46e00;
}
.bookingform-wrapper #children-ages .child-list ul li select:focus {
  outline: none;
}
.bookingform-wrapper #children-ages .child-list ul li > span:after {
  content: "";
  background-image: url("../css/images/dynamic_calendar/select_arrows.png");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-color: transparent;
  background-position: 0 center;
}
@media (max-width: 770px) {
  .bookingform-wrapper #children-ages .child-list ul li > span:after {
    background-position: 0 center;
  }
}

@keyframes bestprice-loading {
  0% {
    border: 0 solid #f2f2f2;
  }
  20% {
    border: 8px solid #f2f2f2;
    width: 0;
    height: 0;
  }
  100% {
    border: 8px solid #f2f2f2;
    width: 100%;
    height: 100%;
  }
}
.tooltipster-default {
  background: rgb(0, 179, 190);
  border: 2px solid rgb(0, 179, 190);
}