$isLight: true;
$defaultFont:  'Roboto', serif;
$defaultBg: #fff;
$defaultColor: #333;
$defaultSeparator: #ddd;

$calendarFirstColor: rgb(0, 179, 190);
$calendarFirstColorAssociate: #fff;

$calendarSecondColor: #e46e00;
$calendarSecondColorAssociate: #fff;

$calendarHighlightColor: #d81a60;
$calendarHighlightColorAssociate: #fff;

$isSideLight: true;
$sideBg: #f2f2f2;
$sideColor: $defaultColor;
$sideSeparator: $defaultSeparator;

$dayDefaultBg: #f2f2f2;
$dayDefaultColor: #666;
$dayInRangeBg: $calendarFirstColor;
$dayInRangeColor: $calendarSecondColorAssociate;
$dayHoverBg: $calendarFirstColor;
$dayHoverColor: $calendarFirstColorAssociate;

$bestPrice: yellowgreen;
$lastRoom: #da2e01;
$minStay: $calendarFirstColor;

$calendarTitleColor: $calendarFirstColorAssociate;
$calendarTitleBg: $calendarFirstColor;


.bookingform-wrapper {
  font-family: $defaultFont;
  background-color: $defaultBg;
  -webkit-box-shadow: 0 0 30px rgba(#000, 0.3);
  -moz-box-shadow: 0 0 30px rgba(#000, 0.3);
  box-shadow: 0 0 30px rgba(#000, 0.3);
  .close {
    background-color: rgba(#000, 0.3);
    color: #fff;
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    -ms-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease;
    width: 50px;
    line-height: 50px;
    height: 50px;
    &:before{
      line-height: 50px;
    }
    &:hover {
      background-color: $calendarSecondColor;
      color: $calendarSecondColorAssociate;
    }
  }
  .calendar_title {
    background-color: $calendarTitleBg;
    color: $calendarTitleColor;
    height: 50px;
    line-height: 50px;
  }
  .side {
    background-color: $sideBg;
    color: $sideColor;
    padding-top: 5px;
    padding-bottom: 10px;
    font-size:11px;
    .fields {
      .enfant{
        display:none;
      }
      .field {
        border-bottom-color: $sideSeparator;
        .tooltip_container{
          text-align: right;
          font-size: 20px;
          color: $calendarHighlightColor;
          span{
            cursor: pointer;
            &:hover {
              color: $calendarSecondColor;
            }
          }
        }
        label {
          text-transform: uppercase;
        }
        select {
          color: $sideColor;
          text-transform: capitalize;
          padding-right: 30px;
          &:focus {
            outline: none;
          }
        }
        &.select_hotel {
          display: none;
        }
        &.select_hotel,
        &.select_treatment,
        &.adults,
        &.children {
          > div {
            &:after {
              content: '';
              background: {
                image: url('../css/images/dynamic_calendar/select_arrows.png');
                repeat: no-repeat;
                attachment: scroll;
                color: transparent;
              }
              @if ($isSideLight == true) {
                background-position: 0 center;
              } @else {
                background-position: -20px center;
              }
            }
          }
        }
        &.iata {
          &:hover {
            border-bottom-color: $calendarSecondColor;
          }
          #AccessCode{
            line-height: 25px;
            height: 25px;
            text-transform: none !important;
          }
          input {
            color: $sideColor;
            &:focus {
              outline: none;
            }
          }
        }
        &.bestprice {
          height: 50px;
          @media (max-width: 770px) {
            height: 80px;
          }
          label{
            margin-bottom: 0;
            @media (max-width: 770px) {
              margin-bottom: 5px;
            }
          }
          #bestprice {
            color: $calendarHighlightColor;
          }
          #bestprice-loading {
            margin: 0;
            width: 30px;
            height: 30px;
            position: absolute;
            right: 0;
            bottom: -10px;
            background-color: $calendarHighlightColor;
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            &:after {
              content: '';
              position: absolute;
              border-radius: 50%;
              top: 50%;
              left: 50%;
              border-color: $sideBg;
              -webkit-transform: translate(-50%, -50%);
              -moz-transform: translate(-50%, -50%);
              -ms-transform: translate(-50%, -50%);
              -o-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
              animation: bestprice-loading 1s ease forwards infinite;
            }
          }
        }
        &.booknowbuttoncontainer {
          input {
            border-radius: 5px;
            font-family: $defaultFont;
            text-transform: uppercase;
            background: $calendarHighlightColor;
            color: $calendarHighlightColorAssociate;
            -webkit-transition: 0.3s ease;
            -moz-transition: 0.3s ease;
            -ms-transition: 0.3s ease;
            -o-transition: 0.3s ease;
            transition: 0.3s ease;
            height:45px;
            &:hover {
              background: $calendarSecondColor;
              color: $calendarSecondColorAssociate;
            }
          }
          .quick-search-cancel {
            color: $sideColor;
            -webkit-transition: 0.3s ease;
            -moz-transition: 0.3s ease;
            -ms-transition: 0.3s ease;
            -o-transition: 0.3s ease;
            transition: 0.3s ease;
            font-size:10px;
            margin-top: 15px;
            &:hover {
              color: $calendarSecondColor;
            }
          }
        }
      }
    }
  }
  #booking-calendar {
    .ui-widget {
      font-family: $defaultFont;
    }
    .ui-datepicker.ui-datepicker-inline {
      .ui-datepicker-header {
        .ui-datepicker-title {
          font-family: $defaultFont;
          color: $defaultColor;
          font-size: 18px;
          text-transform: uppercase;
          font-weight: normal;
          font-style: normal;
          text-align: center;
        }

        .ui-corner-all {
          &:after {
            background: {
              image: url('../css/images/dynamic_calendar/calendar_arrows.png');
              repeat: no-repeat;
              color: transparent;
              attachment: scroll;
            }
          }
          &.ui-state-hover {
            background: rgba(#000, 0.1);
          }
        }
        .ui-datepicker-prev {
          &:after {
            content: '';
            @if ($isLight == true) {
              background-position: 0 0;
            } @else {
              background-position: 0 -40px;
            }
          }
        }
        .ui-datepicker-next {
          &:after {
            content: '';
            @if ($isLight == true) {
              background-position: -40px 0;
            } @else {
              background-position: -40px -40px;
            }
          }
        }
      }
    }
    .ui-datepicker-calendar {
      thead {
        th span {
          color: $defaultColor;
          font-size: 12px;
        }
      }
      tbody {
        tr {
          border: 0;
        }
        td {
          &:hover {
            .ui-state-default {
              background-color: $dayHoverBg;
              color: $dayHoverColor;
            }
            span.data {
              color: $dayHoverColor;
            }
          }
          span.data {
            color: $dayDefaultColor;
          }
          &.last-room-available:after {
            border-top-color: $lastRoom;
            border-right-color: $lastRoom;
          }
          &.best-price:after {
            border-top-color: $bestPrice;
            border-right-color: $bestPrice;
          }
          &.last-room-available.best-price:after {
            border-top-color: $bestPrice;
            border-right-color: $lastRoom;
          }
          &.min-stay {
            > .ui-state-default:after {
              background: $minStay;
            }
          }
          &.date-in-range {
            .ui-state-default {
              background-color: $dayInRangeBg;
              color: $dayInRangeColor;
            }
            span.data{
              color: $dayInRangeColor;
            }
          }
          &.ui-state-disabled{
            opacity: 0.5;
          }
          .ui-state-default {
            background: $dayDefaultBg;
            color: $dayDefaultColor;
          }
        }
      }
    }
  }
  .legend_wrap{
    clear: both;
    position: relative;
    display: inline-block;
    width: 100%;
  }
  .legend_wrap .legend {
    .best,
    .last {
      color: $defaultColor;
      font-family: $defaultFont;
      font-size: 13px;
    }
    .best:before {
      border-top-color: $bestPrice;
      border-right-color: $bestPrice;
    }
    .last:before {
      border-top-color: $lastRoom;
      border-right-color: $lastRoom;
    }
    .minimum_stay:before {
      background: $minStay;
    }
  }

  #children-ages {
    border-top-color: $defaultSeparator;
    @media (max-width: 770px) {
      border: 1px solid $sideSeparator;
    }
    .label {
      text-transform: uppercase;
      color: $defaultColor;
      font-size: 18px;
    }
    .child-list ul li {
      label {
        text-transform: capitalize;
        color: $defaultColor;
        font-size: 13px;
      }
      select {
        color: $defaultColor;
        border-color: $defaultSeparator;
        padding-right: 30px;
        font-size: 12px;
        &:focus,
        &:hover {
          border-color: $calendarSecondColor;
        }
        &:focus {
          outline: none;
        }
      }
      > span {
        &:after {
          content: '';
          background: {
            image: url('../css/images/dynamic_calendar/select_arrows.png');
            repeat: no-repeat;
            attachment: scroll;
            color: transparent;
          }
          @if ($isLight == true) {
            background-position: 0 center;
          } @else {
            background-position: -20px center;
          }
          @media (max-width: 770px) {
            @if ($isSideLight == true) {
              background-position: 0 center;
            } @else {
              background-position: -20px center;
            }
          }
        }
      }
    }
  }
}

@keyframes bestprice-loading {
  0% {
    border: 0 solid $sideBg;
  }
  20% {
    border: 8px solid $sideBg;
    width: 0;
    height: 0;
  }
  100% {
    border: 8px solid $sideBg;
    width: 100%;
    height: 100%;
  }
}
.tooltipster-default {
  background: $calendarFirstColor;
  border: 2px solid $calendarFirstColor;
}

